.App {
  text-align: left;
  margin: 3vmin;
}

.App-logo {
  width: 10vmin;
  pointer-events: none;
}

.App-header-title {
  width: 20vmin;
  color: black;
}

.App-header {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(3px + 2vmin);
  color: black;
}
